@media screen and (max-width: 1565px) 
{
    .sidebar .nav-main > ul li a
    {
        font-size: 12px;
        padding: 8px 6px;
    }

    .sidebar .nav-main > ul li .nav-menu-sub-item a
    {
        padding-left: 6px;
    }
}


@media screen and (max-width: 1400px) 
{
    *
    {
        /* color: blue; */
        font-size: 14px;
    }

    .guide > img
    {
        height: 35px!important;
    }

    .guide > a
    {
        font-size: 14px;
    }

    .sidebar .carousel-wrapper .carousel
    {
        height: 10rem;
    }

    .burger-button, .sidebar
    {
        left: 0;
        top: 0;
    }
    
    .burger-button
    {
        background-color: #213668;
        width: 100px;
        height: 100vh;
        padding-top: 1rem;
        z-index: 3;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .sidebar
    {
        position: fixed;
        z-index: 4;
        animation: slide-right 200ms linear;
        display: none;
    }

    .tuile
    {
        width: 480px;
        height: 180px;
        margin: 8px;
        text-align: center;
    }

    .content-wrap
    {
        margin-left: 7.2rem;
    }
}


@media screen and (max-width: 1200px) 
{
    /* *
    {
        color: red;
    } */

    .sidebar
    {
        width: 250px;
    }

    .img-burger
    {
        height: 35px;
    }

    .img-close
    {
        height: 16px;
    }

    .tuile
    {
        width: 330px;
        height: 180px;
        margin: 8px;
        text-align: center;
    }

    .content-table td
    {
        padding: 0.8rem;
    }
}


@media screen and (max-width: 1055px) 
{
    *
    {
        font-size: 11px;
    }

    .search-dossier-close-icon
    {
        height: 11px;
    }

    .burger-button
    {
        width: 80px;
    }
}

@media screen and (min-width: 1010px) 
{
    .mat-column-droits
    {
        display: none;
    }
}

@media screen and (max-width: 1010px) 
{
    .header-droits-acces,
    .mat-column-droits_access,
    .mat-column-gestion_dossiers,
    .mat-column-copie_messages,
    .mat-column-gestion_convention_facturation,
    .mat-column-saisie_donnees
    {
        display: none;
    }
}

@media screen and (max-width: 925px) 
{
    .mat-column-warning,
    .mat-column-badge,
    .mat-column-empty
    {
        display: none;
    }

    .mat-column-annee,
    .mat-column-abonnes_eau_potable,
    .mat-column-abonnes_eau_brute,
    .mat-column-abonnes_assainissement_collectif,
    .mat-column-abonnes_assainissement_non_collectif
    {
        width: 80px;
    }

    .header-table th
    {
        padding: 0;
    }
}

@media screen and (max-width: 850px) 
{
    app-search-dossiers > .searchForm
    {
        float: unset;
        margin-right: 2rem;
    }

    app-searchbar
    {
        float: unset;
        display: flex;
        justify-content: flex-end;
    }

    app-searchbar .searchValue
    {
        width: 150px!important;
    }

    app-elements-on-table .select
    {
        width: 90px!important;
    }
}

@media screen and (max-width: 820px) 
{
    .mat-mdc-cell
    {
        line-height: normal!important;
    }
}

@media screen and (max-width: 800px) 
{
    .toggleService
    {
        display: none;
    }

    .toggleServiceHeader
    {
        display: inline-block!important;
    }
}

@media screen and (max-width: 600px) 
{
    app-search-dossiers > .searchForm
    {
        margin-right: 3rem;
    }

    app-searchbar > div
    {
        margin-right: 1.2rem;
    }
}

@media screen and (max-width: 576px) 
{
    app-search-dossiers > .searchForm, app-searchbar > div
    {
        display: none;
    }
}


@keyframes slide-right 
{
    from {
        margin-left: -100%;
    }
    to {
        margin-left: 0%;
    }
}
  
@keyframes slide-left 
{
    from {
        margin-left: 100%;
    }
    to {
        margin-left: 0%;
    }
}