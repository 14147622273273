*{
    font-size: 16px;
    line-height: 1.2;
    box-sizing: border-box;
}
a{
    color: inherit;
    text-decoration: none;
}
a:hover{
    text-decoration: underline;
}
.container
{
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: 100%;
}
.tile{
    width: 520px;
    height: 240px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative; 
    margin: 8px;
}
.tile-small{
    height: 80px;
}
.tile .half{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    max-width: 50%;
}
.tile .half.left{
    text-align: center;
    display: block;
}
.tile .half.right{
    padding-left: 70px;
    text-align: left;
    display: block;
}
.tile .data-number{
    font-size: 173px;
}
.tile .data-label{
    font-size: 36px;
    font-weight: 600;
}
.tile .indication{
    position: absolute;
    top: 16px;
    right: 16px;
}
.tile .indication .icon{
    position: relative;
}
.tile .indication .text{
    display: none;
    position: absolute;
    top: 0;
    right: 20px;
    padding: 8px;
}
.tile .indication .icon:hover{
    cursor: pointer;
}
.tile .indication .icon:hover .text{
    display: block;
}
.tile.tile-small .data-number{
    font-size: 34px;
    margin-right: 24px;
    font-weight: normal;
}
.tile.tile-small .data-label{
    font-size: 24px;
    font-weight: normal;
}
.tile.tile-list{
    display: block;
    text-align: left;
    padding: 0;
}
.tile.tile-list .tile-header,
.tile.tile-list .tile-content{
    padding: 0 24px;
}
.tile.tile-list .tile-header{
    display: flex;
    align-items: center;
    height: 80px;
}
.tile.tile-list .tile-header h2{
    display: block;
}
.tile.tile-list .tile-content .tile-content-row{
    padding: 4px 0;
}
.tile-content
{
    height: 150px;
    overflow-y: auto;
    scrollbar-width: thin;
}
.tile.tile-list .tile-content .tile-content-row .item{
    display: inline-block;
}
.main{
    padding: 20px 10px;
}
ul li{
    list-style: none;
}
ul, li{
    margin: 0;
    padding: 0;
}
.nav{
    width: 100%;
    position: relative;
    font-family: "Inter-Bold";
}
.nav .buttons{
    height: 40px;   
    display: none;
}

/* SIDEBAR */
#cta-menu-open{
    z-index: 2;
}
#cta-menu-open,
#cta-menu-close{
    padding: 3px 0;
    position: absolute;
    top: 2px;
    right: 0;
    color: white;
    font-size: 24px;
    line-height: 1;
}

.burger-button
{
    display: none;
}

.sidebar{
    color: white;
    padding: 0; /**/
}
.sidebar a{
    color: white;
}
.sidebar .nav-main ul,
.sidebar .nav-main li{
    width: 100%;
}
.sidebar .nav-main a{
    display: block;
}

.sidebar .nav-main > ul li a{
    padding: 12px;
    line-height: 0.4;
    width: 100%;
}
/* FIN SIDEBAR */



.main
/* ,
.header */
{
    margin-left: 0px;
    margin-right: 0px;
}
.header{
    height: 80px;
    align-items: center;
    padding: 0 15px;
}
.header .brand,
.header .brand img{
    height: 100%;
}
.header .user{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.header .profile .img{
    margin-left: 20px;
}
.header .profile .menu{
    top: 50px;
    right: 0;
}
.header .profile .menu{
    top: 40px;
    right: 0;
}
.header .profile .menu li{
    text-align: right;
    padding: 8px 12px;
}
.header .profile:hover .menu,
.header .profile .menu:hover{
    display: block;
}
.header .profile .menu li:hover{
    background-color: #2794D8;
    color: white;
}
.main-header .ariane{
    display: flex;
    align-items: center;
}
.main .main-header .ariane .picto{
    margin-right: 8px;
}.main .main-header .ariane .item{
    display: inline-block;
    margin-left: 8px;
}


/* images */
.picto{
    width: 22px;
    height: auto;
}
.picto-x2{
    /* width: 40px; */
    width: 32px;
    height: auto;
}

.breadcrumb-icon
{
    height: 15px;
}


.content-wrap
{
    position: relative;
    min-height: 100vh;
}

.badge
{
    border-radius: 8px;
}

input[type="text"],
input[type="search"],
input[type="text"]::placeholder,
input[type="search"]::placeholder
{
    color: #828282;
}

app-searchbar
{
    float: right;
}

.pre
{
   font-family: var(--bs-body-font-family);
   white-space: pre-wrap;       /* Since CSS 2.1 */
   white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
   white-space: -pre-wrap;      /* Opera 4-6 */
   white-space: -o-pre-wrap;    /* Opera 7 */
   word-wrap: break-word;       /* Internet Explorer 5.5+ */
   overflow-y: hidden;
}